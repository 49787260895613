<template>
  <div :style="backCss">
    <!-- ||!propsParams.hasFactor -->
    <a-dropdown
      :trigger="['click']"
      :disabled="isDis(propsParams)"
      :visible="visible"
      @visibleChange="visibleChange"
      transitionName=""
    >
      <a-button v-if="!isMyTaskList" class="reject-btn" @click.stop="getRejectStages">
        <span class="circular" v-if="propsParams.taskStatus === '等待'" style="background: grey;"> </span>
        <span v-if="propsParams.taskStatus === '等待'" style="margin-left:5px;">等待</span>
        <span class="circular" v-if="propsParams.taskStatus === '可开始'" style="background: red"> </span>
        <span v-if="propsParams.taskStatus === '可开始'" style="margin-left:5px;">可开始</span>
        <span class="circular" v-if="propsParams.taskStatus === '已完成'" style="background: green"> </span>
        <span v-if="propsParams.taskStatus === '已完成'" style="margin-left:5px;">已完成</span>
        <span class="circular" v-if="propsParams.taskStatus === '驳回'" style="background: orange"> </span>
        <span v-if="propsParams.taskStatus === '驳回'" style="margin-left:5px;">驳回</span>
      </a-button>
      <a-button v-else class="reject-btn reject-btn-list" @click.stop="getRejectStages">
        <span class="circular" v-if="propsParams.taskStatus === '等待'" style="background: grey;"> </span>
        <span v-if="propsParams.taskStatus === '等待'" style="margin-left:5px;">等待</span>
        <span class="circular" v-if="propsParams.taskStatus === '可开始'" style="background: red"> </span>
        <span v-if="propsParams.taskStatus === '可开始'" style="margin-left:5px;">可开始</span>
        <span class="circular" v-if="propsParams.taskStatus === '已完成'" style="background: green"> </span>
        <span v-if="propsParams.taskStatus === '已完成'" style="margin-left:5px;">已完成</span>
        <span class="circular" v-if="propsParams.taskStatus === '驳回'" style="background: orange"> </span>
        <span v-if="propsParams.taskStatus === '驳回'" style="margin-left:5px;">驳回</span>
      </a-button>
      <a-menu slot="overlay" transitionName="">
        <a-menu-item v-if="false">
          <span class="circular" style="background: grey"> </span>
          <span> 等待 </span>
        </a-menu-item>
        <a-menu-item
          v-if="propsParams.taskStatus == '已完成'"
          @click="
            () =>
              handleTasks({
                label: '可开始'
              })
          "
        >
          <span class="circular" style="background: red"> </span>
          <span> 可开始 </span>
        </a-menu-item>
        <a-menu-item
          v-if="propsParams.taskStatus !== '已完成' && propsParams.stageName != '甲方反馈'"
          @click="
            () =>
              handleTasks({
                label: '已完成'
              })
          "
        >
          <span class="circular" style="background: green;margin-left:3px"> </span>
          <span style="margin-left:2px"> 已完成 </span>
        </a-menu-item>
        <a-menu-item
          @click="
            () =>
              handleTasks({
                label: '已完成',
                hasFeedback: false
              })
          "
          class="status-item"
          v-if="propsParams.taskStatus !== '已完成' && propsParams.stageName == '甲方反馈' && !propsParams.hasFeedback"
        >
          <div style="texe-align:center;padding-left:10px">无反馈</div>
        </a-menu-item>
        <a-menu-item
          @click="() => (this.rejectShow = true)"
          class="status-item"
          v-if="propsParams.taskStatus !== '已完成' && propsParams.stageName == '甲方反馈' && !rejectShow"
        >
          <div style="texe-align:center;padding-left:10px">有反馈</div>
        </a-menu-item>
        <a-menu-item
          @click="
            () =>
              handleTasks({
                label: '已完成',
                hasFeedback: true
              })
          "
          class="status-item"
          v-if="propsParams.taskStatus !== '已完成' && propsParams.stageName == '甲方反馈' && rejectShow"
        >
          <div class="circular" style="background: green;margin-left:3px"></div>
          <span style="margin-left:5px">已完成</span>
        </a-menu-item>
        <template
          v-if="
            propsParams.taskStatus !== '已完成' &&
              propsParams.taskStatus !== '驳回' &&
              propsParams.taskName !== '给稿' &&
              rejectStages.length > 0 &&
              rejectShow
          "
        >
          <a-menu-item v-if="rejectStages.length > 1" v-for="(item, idx) in rejectStages" :key="idx">
            <a-checkbox
              v-model="item.checked"
              @change="
                e => {
                  onChange(e, item)
                }
              "
            >
              <a-dropdown :trigger="['hover']" transitionName="">
                <div style="line-height:30px;">驳回-{{ item.stageName }}</div>
                <div
                  slot="overlay"
                  :style="
                    `position: absolute; top: -4vh; left: ${
                      isMytaskNew ? '-4.5vw' : '4.5vw'
                    };box-shadow: 0 2px 8px rgb(0 0 0 / 15%);`
                  "
                >
                  <a-menu>
                    <a-menu-item style="height:30px;line-height:30px;" @click="priorityChange(item, '高')" key="HIGH"
                      >高
                    </a-menu-item>
                    <a-menu-item
                      style="height:30px;line-height:30px;"
                      key="MIDDLE"
                      @click="priorityChange(item, '普通')"
                      >普通
                    </a-menu-item>
                    <a-menu-item style="height:30px;line-height:30px;" @click="priorityChange(item, '低')" key="LOW"
                      >低
                    </a-menu-item>
                  </a-menu>
                </div>
              </a-dropdown>
            </a-checkbox>
          </a-menu-item>

          <a-sub-menu v-else v-for="(item, idx) in rejectStages" :key="'submenu' + idx">
            <div
              slot="title"
              style="display:flex;align-items:center;line-height:30px;min-width: 100%;"
              @click="priorityChange(item, '普通')"
            >
              <div class="statusPointer" :style="{ background: 'orange' }"></div>
              <div style="margin-left: 2px;">&nbsp;驳回-{{ item.stageName }}</div>
            </div>
            <!-- <div slot="overlay"
                style=" position: absolute; top: -4vh; left: 4.5vw;box-shadow: 0 2px 8px rgb(0 0 0 / 15%);"> -->

            <a-menu-item style="line-height:30px;" @click="priorityChange(item, '高')" key="HIGH">高 </a-menu-item>
            <a-menu-item style="line-height:30px;" key="MIDDLE" @click="priorityChange(item, '普通')"
              >普通
            </a-menu-item>
            <a-menu-item style="line-height:30px;" @click="priorityChange(item, '低')" key="LOW">低 </a-menu-item>
          </a-sub-menu>
        </template>
        <a-menu-item
          v-if="
            rejectStages.length > 1 &&
              propsParams.taskStatus !== '已完成' &&
              propsParams.taskStatus !== '驳回' &&
              rejectShow
          "
          style="background-color: #fff !important;"
        >
          <div class="task-btn">
            <div
              @click="
                () => {
                  this.visible = !this.visible
                }
              "
              style="width:50%"
            >
              取消
            </div>
            <div :style="`color:${color};width:50%`" @click="submitTask">确定</div>
          </div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <term-list-modal ref="termListModal" />
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import axios from 'axios'
import Vue from 'vue'
import termListModal from '@/components/termListModal'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { checkPermission } from '@/utils/hasPermission'
import { upstatusDownloadFile } from '@/utils/util'

export default {
  components: {
    termListModal
  },
  props: {
    propsParams: {
      type: Object,
      default: {}
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    taskList: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    isMyTask: {
      type: Boolean,
      default: false
    },
    isMyTaskList: {
      type: Boolean,
      default: false
    },
    isMytaskNew: {
      type: Boolean,
      default: false
    },
    isCalendar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      url: {},
      rejectStages: [],
      visible: false,
      checkedList: [],
      userInfo: {},
      color: '',
      rejectShow: true,
      deleteBatch: `/production/delete_task_batch`,
      isTongChou: false
    }
  },
  methods: {
    priorityChange(item, type) {
      if (this.rejectStages.length == 1) {
        this.rejectStages[0].stageName = this.rejectStages[0].stageName.split('-')[0] + '-' + type
        this.checkedList = this.rejectStages
        this.submitTask()
      }
      item.stageName = item.stageName.split('-')[0] + '-' + type
      item.checked = true
      if (
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      }
    },
    onChange(e, item) {
      if (
        e.target.checked &&
        this.checkedList.every(i => {
          return i.stageId != item.stageId
        })
      ) {
        this.checkedList.push(item)
      } else if (
        !e.target.checked &&
        this.checkedList.some(i => {
          return i.stageId == item.stageId
        })
      ) {
        this.checkedList = this.checkedList.filter(i => {
          return i.stageId != item.stageId
        })
      }
    },
    submitTask() {
      let targetList = []
      this.checkedList.forEach(item => {
        if (item.stageName.split('-').length > 1) {
          targetList.push({
            stageId: item.stageId,
            priority:
              item.stageName.split('-')[1] == '高'
                ? 'HIGH'
                : item.stageName.split('-')[1] == '普通'
                ? 'MIDDLE'
                : item.stageName.split('-')[1] == '低'
                ? 'LOW'
                : ''
          })
        } else {
          targetList.push({ stageId: item.stageId, priority: 'MIDDLE' })
        }
      })
      if (!this.checkedList?.length) return
      this.handleTasks({ label: '驳回', targetList })
    },
    checkPermission() {
      return (
        checkPermission('production:task:button:change') ||
        (this.propsParams && this.userInfo.id == this.propsParams.ownerId)
      )
    },
    isDis(propsParams) {
      let isDis = false
      if (!this.checkPermission()) return true
      this.taskList?.forEach((item, index) => {
        if (item.id == this.propsParams.id && index != 0) {
          if (this.taskList[index - 1].taskStatus === '驳回' && this.propsParams.taskStatus === '驳回') {
            isDis = true
          }
        }
      })
      if (!propsParams.rejectTaskId && propsParams.taskStatus === '驳回') {
        return true
      }
      if (propsParams.taskStatus === '等待' && propsParams.taskName != '给稿') {
        return true
      } else {
        if (propsParams.taskStatus === '已完成') {
          return !this.isTongChou
        } else {
          return isDis
        }
      }
    },
    visibleChange() {
      setTimeout(() => {
        this.visible = !this.visible
      }, 100)
    },
    async handleTasks(item) {
      this.visible = !this.visible
      const { label } = item
      const { taskId, taskStatus, stageName, stageId } = this.propsParams
      if (stageName !== '给稿' && taskStatus === '等待') {
        this.$message.info({
          content: '等待状态不能点击已完成'
        })
        return
      }

      if (taskStatus === '已完成') {
        getAction('/tasks/resetTasks?taskId=' + taskId).then(res => {
          this.loadData()
          this.$message.success('可开始')
        })
        return
      }

      if (taskStatus === label) {
        this.$message.info({
          content: '已经是' + label + '状态了'
        })
        return
      }

      let url = ''
      if (label === '可开始') {
        url = `/tasks/start/${taskId}`
      } else if (label === '已完成') {
        if (
          !this.taskList
            ?.filter(item => {
              return item.productionName
            })
            .every(item => {
              return item.deadline
            }) &&
          !this.isMyTask &&
          !this.isCalendar
        ) {
          this.$message.error('请将所有任务设置截止日，再点击已完成')
          return
        }
        let hasFeedback = item.hasFeedback === undefined ? '' : item.hasFeedback ? true : false
        url = `/tasks/complete?taskId=${taskId}&hasFeedback=${hasFeedback}&checkDetailValue=${true}`
        let that = this
        if (item.hasFeedback === false) {
          this.$confirm({
            content: h => <div>请确认是否真的没有反馈</div>,
            okText: '是',
            cancelText: '否',
            async onOk() {
              const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
              if (response.code == 200) {
                if (response.data.productionList && response.data.productionList.length) {
                  that.$refs.termListModal.open(response.data.productionList)
                } else {
                  getAction(url).then(async res => {
                    if (res.success) {
                      that.$message.success(that.getStatusLabel(label))
                      that.rejectStages = []
                      upstatusDownloadFile([taskId])
                      that.loadData()
                    }
                    if (res.code === 510) {
                      that.$message.warning(res.message)
                    } else if (res.code == 500) {
                      that.$message.error(res.msg || res.message)
                    }
                  })
                }
              }
            },
            onCancel() {},
            class: ''
          })
          return
        }
      } else if (label == '驳回') {
        let hasFeedback = ''
        if (stageName == '甲方反馈') {
          hasFeedback = true
        }
        postAction('/tasks/reject', { taskId, targetList: item.targetList, hasFeedback }).then(res => {
          if (res.code != 500) {
            this.$message.success('驳回成功')
            this.rejectStages = []
            this.loadData()
          } else {
            this.$message.error(res.message)
          }
        })
        return
      }
      if (label != '已完成') {
        getAction(url).then(res => {
          if (res.success) {
            this.$message.success(label)
            this.rejectStages = []
            this.loadData()
          }
          if (res.code === 510) {
            this.$message.warning(res.message)
          } else if (res.code == 500) {
            this.$message.error(res.msg || res.message)
          }
        })
      } else {
        if (label == '已完成') {
          if (
            this.propsParams.useTranslationSystem &&
            (this.propsParams.stageName == '翻译' ||
              this.propsParams.stageName == '校对' ||
              this.propsParams.stageName == '终审')
          ) {
            this.$confirm({
              content: h => <div>请确认当前任务的工作已经全部完成，提前点完成/未完成点完成均会影响后续考核。</div>,
              okText: '是',
              cancelText: '否',
              onOk: async () => {
                const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
                if (response.code == 200) {
                  if (response.data.productionList && response.data.productionList.length) {
                    this.$refs.termListModal.open(response.data.productionList)
                  } else {
                    getAction(url).then(async res => {
                      if (res.success) {
                        if (res.data.noUpdateTasks.length) {
                          if (res.data.noUpdateTasks[0].noFactor) {
                            this.$message.error('请填写系数参数必填项')
                          }
                          if (!res.data.noUpdateTasks[0].noFactor && res.data.noUpdateTasks[0].noKpi) {
                            this.$confirm({
                              content: h => <div>请确认对其他阶段的考核数量是否正确</div>,
                              okText: '是',
                              cancelText: '否',
                              onOk: async () => {
                                url = url + '&ignoreKpi=true'
                                getAction(url).then(async res => {
                                  if (res.success) {
                                    upstatusDownloadFile([taskId])
                                    this.$message.success(label)
                                    this.rejectStages = []
                                    this.loadData()
                                  }
                                  if (res.code === 510) {
                                    this.$message.warning(res.message)
                                  } else if (res.code == 500) {
                                    this.$message.error(res.msg || res.message)
                                  }
                                })
                              },
                              onCancel() {},
                              class: ''
                            })
                          }
                        } else {
                          upstatusDownloadFile([taskId])
                          this.$message.success(label)
                          this.rejectStages = []
                          this.loadData()
                        }
                      }
                      if (res.code === 510) {
                        this.$message.warning(res.message)
                      } else if (res.code == 500) {
                        this.$message.error(res.msg || res.message)
                      }
                    })
                  }
                }
              }
            })
          } else {
            if (!this.propsParams.finishManuscriptFileCount && this.propsParams.stageName == '制作修改') {
              this.$confirm({
                content: h => <div>（{this.propsParams.productOrder}-{this.propsParams.productionName}-{this.propsParams.chapterOrder}-
                    {this.propsParams.stageName}）修改稿数量为0，是否确认完成？</div>,
                okText: '是',
                cancelText: '否',
                onOk: async () => {
                  const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
                  if (response.code == 200) {
                    if (response.data.productionList && response.data.productionList.length) {
                      this.$refs.termListModal.open(response.data.productionList)
                    } else {
                      getAction(url).then(async res => {
                        if (res.success) {
                          upstatusDownloadFile([taskId])
                          this.rejectStages = []
                          if (res.data.noUpdateTasks.length) {
                            console.log(this.isCalendar, res)
                            if (!this.isCalendar) {
                              this.$refs.tasksModel.open(res.data.noUpdateTasks, hasFeedback)
                            } else {
                              this.$emit('openTaskModel', { noUpdateTasks: res.data.noUpdateTasks, hasFeedback })
                            }
                          } else {
                            this.$message.success(label)
                            this.loadData(res.data.updatedTasks)
                          }
                        }
                        if (res.code === 510) {
                          this.$message.warning(res.message)
                        } else if (res.code == 500) {
                          this.$message.error(res.msg || res.message)
                        }
                      })
                    }
                  }
                }
              })
              return
            }
            const response = await getAction('/tasks/checkTermsStatus?taskIds=' + taskId)
            if (response.code == 200) {
              if (response.data.productionList && response.data.productionList.length) {
                this.$refs.termListModal.open(response.data.productionList)
              } else {
                getAction(url).then(async res => {
                  if (res.success) {
                    if (res.data.noUpdateTasks.length) {
                      if (res.data.noUpdateTasks[0].noFactor) {
                        this.$message.error('请填写系数参数必填项')
                      }
                      if (!res.data.noUpdateTasks[0].noFactor && res.data.noUpdateTasks[0].noKpi) {
                        this.$confirm({
                          content: h => <div>请确认对其他阶段的考核数量是否正确</div>,
                          okText: '是',
                          cancelText: '否',
                          onOk: async () => {
                            url = url + '&ignoreKpi=true'
                            getAction(url).then(async res => {
                              if (res.success) {
                                upstatusDownloadFile([taskId])
                                this.$message.success(label)
                                this.rejectStages = []
                                this.loadData()
                              }
                              if (res.code === 510) {
                                this.$message.warning(res.message)
                              } else if (res.code == 500) {
                                this.$message.error(res.msg || res.message)
                              }
                            })
                          },
                          onCancel() {},
                          class: ''
                        })
                      }
                    } else {
                      upstatusDownloadFile([taskId])
                      this.$message.success(label)
                      this.rejectStages = []
                      this.loadData()
                    }
                  }
                  if (res.code === 510) {
                    this.$message.warning(res.message)
                  } else if (res.code == 500) {
                    this.$message.error(res.msg || res.message)
                  }
                })
              }
            }
          }
        } else {
          getAction(url).then(async res => {
            if (res.success) {
              if (res.data.noUpdateTasks.length) {
                if (res.data.noUpdateTasks[0].noFactor) {
                  this.$message.error('请填写系数参数必填项')
                }
                if (!res.data.noUpdateTasks[0].noFactor && res.data.noUpdateTasks[0].noKpi) {
                  this.$confirm({
                    content: h => <div>请确认对其他阶段的考核数量是否正确</div>,
                    okText: '是',
                    cancelText: '否',
                    onOk: async () => {
                      url = url + '&ignoreKpi=true'
                      getAction(url).then(async res => {
                        if (res.success) {
                          upstatusDownloadFile([taskId])
                          this.$message.success(label)
                          this.rejectStages = []
                          this.loadData()
                        }
                        if (res.code === 510) {
                          this.$message.warning(res.message)
                        } else if (res.code == 500) {
                          this.$message.error(res.msg || res.message)
                        }
                      })
                    },
                    onCancel() {},
                    class: ''
                  })
                }
              } else {
                upstatusDownloadFile([taskId])
                this.$message.success(label)
                this.rejectStages = []
                this.loadData()
              }
            }
            if (res.code === 510) {
              this.$message.warning(res.message)
            } else if (res.code == 500) {
              this.$message.error(res.msg || res.message)
            }
          })
        }
      }
    },
    async getRejectStages() {
      this.rejectShow = this.propsParams.stageName == '甲方反馈' ? false : true
      const res = await getAction('/production/canRejectStage', {
        stageId: this.propsParams.stageId,
        productId: this.propsParams.productionId
      })
      try {
        if (res.code == 200) {
          res.data.forEach(item => {
            item.checked = false
          })
          this.rejectStages = res.data
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    }
  },
  created() {},
  mounted() {
    this.color = this.$store.state.app.color
    this.rejectShow = this.propsParams.stageName == '甲方反馈' ? false : true
    let userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = Vue.ls.get(USER_INFO)
    if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin')) {
      this.isTongChou = true
    } else if (userInfo.roles.some(item => item.roleCode == 'tongchou')) {
      if (this.propsParams.adminUser && this.propsParams.adminUser.userId == userInfo.id) {
        this.isTongChou = true
      }
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      let height = 'calc(100vh - 335px)'
      return {
        '--theme-color': color,
        '--table-height': height
      }
    }
  }
}
</script>

<style lang="less">
.reject-btn {
  padding: 0 12px !important;
}
.reject-btn-list {
  border: 1px solid rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.reject-btn-list:hover {
  border: 1px solid var(--theme-color) !important;
}
.task-btn {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  .ant-checkbox {
    margin-top: 3px;
  }
}
.circular {
  display: inline-block;
  width: 10px;
  height: 10px;
  //   background-color: grey;
  border-radius: 100%;
  margin-right: 5px;
  cursor: pointer;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-menu-item:hover {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-menu-item-selected {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
